import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import "../assets/css/Home.css";
import Marquee from "react-fast-marquee";
import CTASection from "./CTASection";
import Loader from "../Components/Loader";
import axios from "axios";
import { Config } from "../Config";
import MainHomeSection from "./MainHomeSection";

// images
import img1 from "../assets/Images/img/Bharat.jpg";
import img2 from "../assets/Images/img/img3.jpg";
import img3 from "../assets/Images/img/india_gob.jpg";
import img4 from "../assets/Images/img/data_gov.jpg";
import img5 from "../assets/Images/img/logo.jpg";
import img6 from "../assets/Images/img/make-in-india-logo.jpg";
import "aos/dist/aos.css";
import Aos from "aos";

const Index = () => {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, []);

  useEffect(() => {
    Aos.init({
      once: false,
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, [])

  const [loading, setLoading] = useState(true);
  const [bannerSection, setBannerSection] = useState({});
  const [gallerySection, setGallerySection] = useState([]);
  const [ctaSection, setctaSection] = useState({});
  const [cta, setCta] = useState([]);
  const [chooseSection, setChooseSection] = useState({});
  const [investSection, setInvestSection] = useState([]);
  const [partnershipSection, setPartnershipSection] = useState({});
  const [contactSection, setContactSection] = useState({});

  useEffect(() => {
    axios.get(`${Config.apiUrl}/homepage`,
      {
        headers: {
          //'Authorization': 'Bearer ',
          'Content-Type': 'application/json',
          'Content-Disposition': 'attachment'
        }
      })
      .then(response => {

        const data = response.data.home_page;
        setBannerSection(data.banner_section);
        setGallerySection(data.gallery_section);
        setctaSection({ ...data.cta_section, projects: data.project_info });
        setCta(data.cta);
        setChooseSection(data.choose_section);
        setInvestSection(data.invest_section);
        setPartnershipSection(data.partnership_section);
        setLoading(false);
      })
      .catch(error => {
        console.error('There was a problem fetching the data:', error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    axios.get(`${Config.apiUrl}/contactpage`,
      {
        headers: {
          //'Authorization': 'Bearer ',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {

        const data = response.data.contact_page;
        setContactSection(data.contact_section);
      })
      .catch(error => {
        console.error('There was a problem fetching the data:', error);
      });
  }, []);

  return (
    <>

      {
        loading && <Loader />
      }
      <Navbar />
      <div>
        <Marquee
          // speed={8} // Speed of the marquee (Optional)
          className="text-red fw-bold bg-white py-2"
          style={{ fontSize: "14px" }}
        >
          <div dangerouslySetInnerHTML={{ __html: bannerSection.hero_section_title }} />
          {/* 33% कोटा महिलाओं एवं */}
        </Marquee>
      </div>

      <MainHomeSection data={{ banner_img: bannerSection.hero_bg, images: gallerySection }} />

      <CTASection data={{ ctaSection: ctaSection, cta: cta, sector: 90 }} />

      {/* Why Choose Section */}
      <section className="py-5 bg-light-blue">
        <div className="container-box py-md-3">
          <div className="">
            <div className="text-center">
              <h2 className="fw-bold text-capitalize text-blue">
                {chooseSection.choose_title}
              </h2>
              <div className="line mt-4 mb-5 mx-auto"></div>
            </div>
            {/* points */}
            <div dangerouslySetInnerHTML={{
              __html: chooseSection.choose_description
                ?.replace('<ol>', '<div class="mb-3">')
                .replaceAll('</ol>', '</div>')
                .replaceAll('<li>', '<div>')
                .replaceAll('</li>', '</div>')

            }} />
          </div>
        </div>
      </section>
      {/* End */}

      {/* Invest Section */}
      <section className="py-5 border-bottom ">
        <div className="container-box py-md-4">
          <div className="">
            {/* points */}
            <div className="text-center">
              <h2 className="fw-bold text-blue text-capitalize ">
                {investSection.invest_title}
              </h2>
              <div className="line mt-4 mb-5 mx-auto"></div>
            </div>
            <div dangerouslySetInnerHTML={{
              __html: investSection.invest_description
                ?.replace('<ol>', '<div class="mb-3">')
                .replace('</ol>', '</div>')
                .replace('<li>', '<div>')
                .replace('</li>', '</div>')

            }} />


          </div>
        </div>
      </section>
      {/* End */}

      <CTASection data={{ ctaSection: ctaSection, cta: cta, sector: 90 }} />

      {/* Development Section */}
      <section className="py-5 border-bottom">
        <div className="container-box py-md-3">
          <div className="">
            <div className="">
              <h2 className="fw-bold text-blue text-center text-capitalize">
                {partnershipSection.partnership_title}
              </h2>
              <div className="line mt-4 mb-5 mx-auto"></div>
            </div>
            {/* points */}
            <div dangerouslySetInnerHTML={{
              __html: partnershipSection.partnership_description
                ?.replace('<ol>', '<div class="mb-3">')
                .replaceAll('</ol>', '</div>')
                .replaceAll('<li>', '<div>')
                .replaceAll('</li>', '</div>')

            }} />
          </div>
        </div>
      </section>
      {/* End */}

      <CTASection data={{ ctaSection: ctaSection, cta: cta, sector: 93 }} />

      {/* Video & Footer */}
      <section className="py-5 bg-light">
        <div className="container-box pt-md-4">
          {/* <div className="row">
            <div className="col-md-6">
              <video controls muted className='w-100'  >
                <source src={video1} type="video/mp4" />
              </video>

            </div>

            <div className="col-md-6 mt-md-0 mt-4">
              <video controls muted className='w-100'  >
                <source src={video2} type="video/mp4" />
              </video>
            </div>
          </div> */}

          <div className=" py-2 mt-4">
            <Marquee
              // speed={8} // Speed of the marquee (Optional)
              className="text-red fw-bold"
              style={{ letterSpacing: "1.5px" }}
            >
              {contactSection.longitude}
            </Marquee>
          </div>

          <div className="row justify-content-center mt-4">
            <div className="col-md-6">
              <div className="d-flex align-items-center ">
                <i className="bi bi-geo-alt-fill display-6 me-3"></i>
                <p className="mb-0">
                  <b>Office  : </b> {contactSection.contact_addresses}
                </p>
              </div>
            </div>

            <div className="col-md-4 mt-md-0 mt-4">
              <div className="d-flex align-items-center">
                <i className="bi bi-envelope me-3 display-6"></i>
                <p className="mb-0">
                  <a
                    href={`mailto:${contactSection.contact_mails}`}
                    className="text-decoration-none text-dark"
                  >
                    {contactSection.contact_mails}
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-2 mt-md-0 mt-4">
              <div className="d-flex align-items-center">
                <i className="bi bi-telephone display-6 me-3"></i>
                <p className="mb-0">
                  <a
                    href={`tel:${contactSection.contact_numbers}`}
                    className="text-decoration-none text-dark"
                  >
                    {contactSection.contact_numbers}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer style={{ backgroundColor: "#155995" }}>
        <div className="py-4 text-white text-center px-4">
          <span>
            {" "}
            Copyright @ 2005. All Right Reserved by haryanaaffordablehousingshop
          </span>
        </div>
      </footer>
    </>
  );
};

export default Index;

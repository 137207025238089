import React, { useEffect, useState } from 'react'
import Navbar from "./Navbar";
import axios from 'axios';
import swal from 'sweetalert';
import Loader from "../Components/Loader";
import { Config } from "../Config";

const Contact = () => {

    const [name, setName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [contactSection, setContactSection] = useState({});

    const [errors, setErrors] = useState({
        name: "",
        emailAddress: "",
        phoneNumber: "",
        message: ""
    })

    useEffect(() => window.scrollTo(0, 0), [])

    useEffect(() => {
        axios.get(`${Config.apiUrl}/contactpage`,
            {
                headers: {
                    //'Authorization': 'Bearer ',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {

                const data = response.data.contact_page;
                setContactSection(data.contact_section);
                setLoading(false);
            })
            .catch(error => {
                console.error('There was a problem fetching the data:', error);

            });
    }, []);

    // window close functionality =========================================
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Show custom popup warning only if the form is not submitted
            const message = 'Your data will be lost if you don\'t save it!';
            event.preventDefault();
            event.returnValue = 'Your data will be lost if you don\'t save it!'; // Necessary for Chrome
        };

        // Assign event listener directly to window.onbeforeunload
        window.onbeforeunload = handleBeforeUnload;

        // Cleanup function to remove the event listener
        return () => {
            window.onbeforeunload = null; // Remove event listener
        };
    }, []);

    // validations for first form
    const handleValidation = () => {

        const newErrors = {};

        if (!name) {
            newErrors.name = 'Name is required';
        }

        if (!message) {
            newErrors.message = 'Message is required';
        }

        if (!emailAddress) {
            newErrors.emailAddress = 'Email is required';
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress)) {
            newErrors.emailAddress = 'Invalid email format';
        }

        if (!phoneNumber) {
            newErrors.phoneNumber = 'Phone number is required';
        } else if (phoneNumber.length > 10 || phoneNumber.length < 10) {
            newErrors.phoneNumber = 'Phone number must be of 10 digits';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }
    // ===========================

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (handleValidation()) {
            setLoading(true)
            try {
                const formData = new FormData()

                formData.append('name', name)
                formData.append('phone', phoneNumber)
                formData.append('email', emailAddress)
                formData.append('message', message)

                const res = await axios.post(`${Config.apiUrl}/contact`, formData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    }
                })
                if (res.data.message == "Message Succesfully sent...") {
                    swal("", "Message Sent Successfully", "success")
                    setName('');
                    setEmailAddress('');
                    setPhoneNumber('');
                    setMessage('');
                }
                setLoading(false)

            } catch (error) {
                console.log(error)
                swal("", "Something Went Wrong", "error")
                setLoading(false)
            }
        }
    }

    return (
        <div>
            {loading && <Loader />}
            <Navbar />

            <section className='py-5 bg-contact' style={{ minHeight: "100vh" }}>
                <div className='container-box py-md-4'>
                    <div className='row flex-md-row flex-column-reverse'>
                        <div className="col-md-6 p-5 bg-white shadow-lg rounded-4 mt-md-0 mt-4" >
                            <form onSubmit={handleSubmit} className='py-3'>
                                <h2 className="fw-bold mb-4 text-blue">Connect With US</h2>
                                <div className=" bd-form-control">
                                    <div className="col-12 mb-4">
                                        <label htmlFor="name">Full Name</label>
                                        <input type="text"
                                            className={`form-field bg-light py-3 border border-2`}
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            placeholder="Enter Full Name" />
                                        {
                                            errors.name && <span className='text-danger'>{errors.name}</span>
                                        }
                                    </div>

                                    <div className="col-12 mb-4">
                                        <label htmlFor="name">Phone Number</label>
                                        <input type="text"
                                            className={`form-field bg-light py-3 border border-2`}
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                            placeholder="Enter Phone Number" />
                                        {
                                            errors.phoneNumber && <span className='text-danger'>{errors.phoneNumber}</span>
                                        }
                                    </div>

                                    <div className="col-12 mb-4">
                                        <label htmlFor="name">Email Address</label>
                                        <input type="text"
                                            className={`form-field bg-light py-3 border border-2`}
                                            value={emailAddress}
                                            onChange={(e) => setEmailAddress(e.target.value)}
                                            placeholder="Enter Email" />
                                        {
                                            errors.emailAddress && <span className='text-danger'>{errors.emailAddress}</span>
                                        }
                                    </div>

                                    <div className="col-12 mb-4">
                                        <label htmlFor="name">Message</label>
                                        <input type="text"
                                            className={`form-field bg-light py-3 border border-2`}
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            placeholder="Enter Message" />
                                        {
                                            errors.message && <span className='text-danger'>{errors.message}</span>
                                        }
                                    </div>
                                </div>

                                <div className="mt-5">
                                    <button className='button bg-green'>
                                        {loading ? "Submitting..." : "SEND MESSAGE"}
                                    </button>
                                </div>
                            </form>
                        </div>

                        <div className="col-md-6 ps-md-5">
                            <div className="d-flex align-items-center bg-dark-opacity p-4 mb-4 ">
                                <i className="bi bi-geo-alt-fill display-6 me-3 text-white"></i>
                                <p className="mb-0 text-white">
                                    <b>Office  : </b> {contactSection.contact_addresses}
                                </p>
                            </div>

                            <div className="d-flex align-items-center bg-dark-opacity p-4 mb-4">
                                <i className="bi bi-envelope me-3 display-6 text-white"></i>
                                <p className="mb-0">
                                    <a
                                        href="mailto:info@haryanaaffordablehousingshop.com"
                                        className="text-decoration-none text-white"
                                    >
                                        {contactSection.contact_mails}
                                    </a>
                                </p>
                            </div>

                            <div className="d-flex align-items-center bg-dark-opacity p-4 mb-4">
                                <i className="bi bi-telephone display-6 me-3 text-white"></i>
                                <p className="mb-0">
                                    <a
                                        href="tel:+91 9289744505"
                                        className="text-decoration-none text-white"
                                    >
                                        {contactSection.contact_numbers}
                                    </a>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="row ">
                <div className="col-12">

                    <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3125.61634983339!2d77.10391832076282!3d28.437787148524638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDI2JzE2LjAiTiA3N8KwMDYnMTMuOSJF!5e0!3m2!1sen!2sin!4v1709639473223!5m2!1sen!2sin" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" className='map'></iframe>

                </div>
            </section>

            <footer style={{ backgroundColor: "#155995" }}>
                <div className="py-4 text-white text-center px-4">
                    <span> Copyright @ 2005. All Right Reserved by haryanaaffordablehousingshop</span>
                </div>
            </footer>
        </div>
    )
}

export default Contact
export const Config = {
    appUrl: "/",
    // apiUrl: 'https://192.168.0.2/unify/haryana/admin/api',
    // apiUrl: 'https://ztpl.net/haryanashop/admin/api',
    apiUrl: 'https://haryanaaffordablehousingshop.com/admin/api',
    imageUrl: 'https://haryanaaffordablehousingshop.com/admin/assets/front/img',
    docUrl: 'https://haryanaaffordablehousingshop.com/admin/assets/lfm/pdfs/'

    // apiUrl: 'https://deendayalplot.com/admin/api',
    // imageUrl: 'https://deendayalplot.com/admin/assets/front/img',
    // docUrl: 'https://deendayalplot.com/admin/assets/lfm/pdfs/'
}
import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import "../../assets/css/bazaar.css";
import axios from "axios";
import { Config } from "../../Config";
import Loader from "../Loader";

const AffordablePolicy = () => {

    const [policy, setPolicyPage] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }, []);


    useEffect(() => {
        axios.get(`${Config.apiUrl}/policy`,
            {
                headers: {
                    //'Authorization': 'Bearer ',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {

                setPolicyPage(response.data.policy_page.affordable);
                setLoading(false);
            })
            .catch(error => {
                console.error('There was a problem fetching the data:', error);

            });
    }, []);

    return (
        <>
            {loading && <Loader />}
            <Navbar />
            <div className="page-content pb-5">
                <section className="py-5 ">
                    <div className="container-box py-md-2">
                        <div className="text-center">
                            <h2 className="fw-bold text-capitalize ">Policy</h2>
                            <div className="line mt-4 mb-5 mx-auto"></div>
                        </div>
                        <div className="container">
                            {
                                <div dangerouslySetInnerHTML={{ __html: policy?.affordable_description }} />
                            }
                        </div>
                    </div>
                </section>
            </div>

            {
                policy?.length > 0 &&
                <footer style={{ backgroundColor: "#155995" }} className="footer">
                    <div className="py-4 text-white text-center px-4">
                        <span>
                            {" "}
                            Copyright @ 2005. All Right Reserved by haryanaaffordablehousingshop
                        </span>
                    </div>
                </footer>
            }
        </>
    );
};

export default AffordablePolicy;

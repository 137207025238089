import React, { useEffect, useRef, useState } from 'react'
import Navbar from './Navbar'
import SectorInformation from './SectorInformation'
import BazaarInformation from './BazaarInformation'
import { useLocation } from 'react-router'

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Loader from "../Components/Loader";
import axios from "axios";
import { Config } from "../Config";

const AllSectors = () => {

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }, [])

    const section1Ref = useRef(null);
    const section2Ref = useRef(null);

    const [loading, setLoading] = useState(true);
    const [projectInfo, setProjectInfo] = useState([]);

    const { state } = useLocation()

    useEffect(() => {
        switch (state?.sector) {
            // case "I":
            //     section1Ref.current.scrollIntoView();
            //     break;
            case "II":
                section2Ref.current.scrollIntoView();
                break;
            // case 93:
            //     section3Ref.current.scrollIntoView();
            //     break;
            default:
                break;
        }
    }, []);

    useEffect(() => {
        const getProjectInfo = async () => {

        }

    }, []);

    useEffect(() => {
        const getProjectInfo = async () => {
            axios.get(`${Config.apiUrl}/autoupdate`,
                {
                    headers: {
                        //'Authorization': 'Bearer ',
                        'Content-Type': 'application/json'
                    }
                })
                .then(async (response) => {
                    await axios.get(`${Config.apiUrl}/project_info`,
                        {
                            headers: {
                                //'Authorization': 'Bearer ',
                                'Content-Type': 'application/json'
                            }
                        })
                        .then(response => {
                            const data = response.data.project_page;
                            setProjectInfo(data);
                            setLoading(false);
                        })
                        .catch(error => {
                            console.error('There was a problem fetching the data:', error);
                            setLoading(false);
                        });
                })
                .catch(error => {
                    console.error('There was a problem fetching the data:', error);
                    setLoading(false);
                });
        }
        getProjectInfo()

    }, []);

    const openProjects = projectInfo?.project_info?.filter(project => project.status === 1)

    return (
        <>
            {loading && <Loader />}
            <Navbar />

            <div className='sector-tabs' style={{ minHeight: '72vh', backgroundColor: "#e6edf4" }}>
                <Tabs
                    defaultActiveKey= "open"
                    // {openProjects?.length > 0 ? "open" : "close"}
                    transition={false}
                    id="noanim-tab-example"
                >
                    <Tab eventKey="open" title="Open Projects" disabled={openProjects?.length === 0}>
                        {openProjects?.map((project, index) => (
                            <div key={index} ref={section1Ref}>
                                <SectorInformation
                                    schemaInfo={project}
                                    sectorIndex={project.status}
                                    sector={project.sector}
                                    projectInfo={projectInfo}
                                    index={index} />
                            </div>
                        ))}
                    </Tab>
                    <Tab eventKey="close" title="Closed Projects">
                        {projectInfo?.project_info?.filter(project => project.status === 0)
                            .map((project, index) => (
                                <div key={index}>
                                    <SectorInformation
                                        schemaInfo={{ ...project, msg: "Sorry! Registration is Closed" }}
                                        sectorIndex={project.status}
                                        sector={project.sector}
                                        projectInfo={projectInfo}
                                        index={index} />
                                </div>
                            ))}
                    </Tab>
                    <Tab eventKey="upcoming" title="Upcoming Projects"
                        disabled={projectInfo?.project_info?.filter(project => project.status === 2).length === 0} >

                        {projectInfo?.project_info?.filter(project => project.status === 2)
                            .map((project, index) => (
                                <div key={index}>
                                    <SectorInformation
                                        schemaInfo={{ ...project, msg: "Registration process is not started yet!" }}
                                        sectorIndex={project.status}
                                        sector={project.sector}
                                        projectInfo={projectInfo}
                                        index={index}
                                    />
                                </div>
                            ))}
                    </Tab>

                </Tabs>
            </div>


            {
                projectInfo?.project_info?.length > 0 &&
                < footer style={{ backgroundColor: "#155995" }} >
                    <div className="py-4 text-white text-center px-4">
                        <span> Copyright @ 2005. All Right Reserved by haryanaaffordablehousingshop</span>
                    </div>
                </footer >
            }
        </>
    )
}

export default AllSectors